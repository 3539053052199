import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import useAdjustLink from '../../../hooks/use-adjust-link';

import GooglePlayIcon from '../../../assets/google-play.svg';

const GooglePlayButton = ({
  size,
  className = '',
  campaign = 'default',
  ...rest
}) => {
  const ctaRef = useRef();
  const url = useAdjustLink({ campaign, store: 'google' }, ctaRef);
  return (
    <a
      href={url}
      ref={ctaRef}
      className={`btn ${
        size == 'small' ? 'btn--store-small' : 'btn--store'
      } ${className}`}
      {...rest}
    >
      <GooglePlayIcon />
      <div className="leading-self">
        <small className="block mb-1 R12 text-gray-middle">Get it on</small>
        Google Play
      </div>
    </a>
  );
};

GooglePlayButton.propTypes = {
  size: PropTypes.string,
  className: PropTypes.string,
  campaign: PropTypes.string,
  appleCampaign: PropTypes.string,
  googleCampaign: PropTypes.string,
};

export default GooglePlayButton;
