import React from 'react';
import Logo from '../../components/layout/header/logo';
import AppStore from '../../components/common/buttons/appStore';
import GooglePlay from '../../components/common/buttons/googlePlay';
import MainDownload from '../../components/download-now/template';
import PropTypes from 'prop-types';

const Render = ({ userPlatform, onClickEvent }) => {
  // all users from the simple landing page should be redirected to this
  // specific custom product page
  const appleCPPURL =
    'https://apps.apple.com/us/app/splice-video-editor-maker/id409838725?ppid=511cc357-b143-456a-9569-fb68d168af53';
  return (
    <>
      <div className="flex-grow flex flex-col justify-center">
        <div className="flex flex-col items-center text-center">
          <Logo />
          <h1 className="text-main-title my-4 md:my-6">
            <span className="stroked">Create videos</span>
            <br />
            that blow minds.
          </h1>
        </div>
        <div className="flex flex-col space-y-4 md:space-y-0 md:block mx-auto mt-14">
          {userPlatform === undefined || userPlatform === 'Apple' ? (
            <AppStore
              className="mx-4"
              onClick={onClickEvent}
              target="_blank"
              url={appleCPPURL}
            />
          ) : null}
          {userPlatform === undefined || userPlatform === 'Android' ? (
            <GooglePlay
              className="mx-4"
              onClick={onClickEvent}
              target="_blank"
            />
          ) : null}
        </div>
      </div>
    </>
  );
};

Render.propTypes = {
  userPlatform: PropTypes.string,
  onClickEvent: PropTypes.func,
};

const DownloadNow = () => {
  return <MainDownload body={Render} isDark={true} />;
};

export default DownloadNow;
